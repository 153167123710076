<template>
  <v-container fluid>
    <v-data-table
        :headers="headers"
        :items="getDomains"
        :options.sync="options"
        show-expand
        :loading="loading"
        loading-text="Loading domains... Please wait"
        multi-sort
        @dblclick:row="(item, slot) => slot.expand(!slot.isExpanded)"

    >
      <template #[`item.name`]="{ item }">
        <domain-menu :name="item.name"/>
      </template>
      <template #[`item.creation`]="{ item }">
        <date-display
            v-if="item.scanResults[0] && item.scanResults[0].domainRegistration"
            :date="item.scanResults[0].domainRegistration.creation"
            date-format="DD MMM YYYY"
        />
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
            fab x-small
        >
          <v-icon @click="rescan(item)">
            mdi-redo
          </v-icon>
        </v-btn>
        <backorder-menu-small :domain="item.name"/>
      </template>
      <template #[`item.suggestedPrice`]="{ item }">
        <evaluation-menu :domain-name="item.name"
                         :suggested-price="item.evaluation"/>
      </template>
      <template #[`item.scans`]="{ item }">
        <domain-card :domain-name="item.name"/>
      </template>
      <template #[`expanded-item`]="{ headers, item }">
        <td :colspan="headers.length">
          <domain-details-card
              :domain-name="item.name"
              :reload="false"
          />
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {mapGetters, mapActions, mapState} from "vuex";
import DomainDetailsCard from "../components/domains/DomainDetailsCard.vue";
import DateDisplay from "@/components/DateDisplay";
import BackorderMenuSmall from "@/components/BackorderMenuSmall";
import DomainMenu from "../components/DomainMenu.vue";
import BackordersTable from "@/components/domains/BackordersTable";
import EvaluationMenu from "@/components/EvaluationMenu.vue";
import DomainCard from "@/components/domains/DomainCard.vue";

export default {
  components: {
    DomainCard,
    EvaluationMenu,
    DomainDetailsCard,
    DateDisplay,
    BackorderMenuSmall,
    DomainMenu,
  },
  data() {
    return {
      options: {
        itemsPerPage: 20,
      },
      search: "",
      calendar: false,

      headers: [
        {text: "Drop Date", value: "dropDate"},
        {text: "Domain", value: "name"},
        {text: "Estimate", value: "suggestedPrice"},
        {text: "Scans", value: "scans"},
        {text: "Owners", value: "differentIPSiblingsCount"},
        {text: "Groups", value: "builtWithCatNumber"},
        {text: "Words", value: "wordsCount"},
        {text: "Split", value: "wordsSplit"},
        {text: "Approachable", value: "approachable"},
        {text: "Actions", value: "actions", sortable: false},
      ],

    };
  },
  computed: {
    ...mapGetters("domains", ["getDomains"]),
    ...mapState("domains", ["loading"]),
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    dateFormatted: {
      get() {
        if (!this.date) return null;
        return new Date(this.date).toISOString().slice(0, 10);
      },
      set(value) {
        this.date = new Date(value);
      },
    },
    date: {
      get() {
        return this.$store.state.domains.active_date;
      },
      set(val) {
        this.$store.commit("domains/SET_ACTIVE_DATE", val);
      },
    },

  },

  mounted() {
    const searchFilter = {
      dropDate: new Date()
    }
    // this.$store.dispatch(`${this.$route.name}/search`, searchFilter)
  },
  methods: {
    ...mapActions("domains", ["loadDomainsPageAction", "backorderAction"]),
    ...mapActions("conversations", ["rescanDomainAction"]),

    async dateAdd(days) {
      const newDate = new Date(this.date);
      newDate.setDate(newDate.getDate() + days);
      this.date = newDate;
    },
    backorder(item) {
      let bid = {
        amount: 999,
        conversation: {
          id: null,
        },
        domain: {
          name: item.name,
        },
      };
      this.backorderAction(bid);
    },
    rescan(item) {
      this.rescanDomainAction(item.name);
    },

  },
};
</script>

<style></style>
